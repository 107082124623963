import buildLogger from '@/util/logger'
import { Button } from '@mui/material'
import React from 'react'
import KeyboardCommandKeyIcon from '@mui/icons-material/KeyboardCommandKey'

const logger = buildLogger('QuickCastEvokeButton')

export function QuickCastEvokeButton(): JSX.Element {
  return (
    <Button
      variant="outlined"
      color="primary"
      sx={{
        backgroundColor: (theme) => theme.palette.background.paper,
      }}
      onClick={() =>
        document.dispatchEvent(
          new KeyboardEvent('keydown', {
            key: 'k',
            metaKey: true,
          })
        )
      }
    >
      <KeyboardCommandKeyIcon fontSize="small" />
      <pre style={{ margin: 0 }}>&nbsp;K</pre>
    </Button>
  )
}
