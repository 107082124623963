import React, { useMemo } from 'react'
import { DocumentNode } from 'graphql'
import { selectAtom, useAtomValue } from 'jotai/utils'
import { getDocumentName } from '../../util/gqlUtil'
import buildLogger from '../../util/logger'
import { JotaiForm } from '../state/useJotaiForm'
import { WithUrql } from '../state/useJotaiUrqlQuery'
import PageLoadingPlaceholder, { PageLoadingPlaceholderProps } from './pageLoadingPlaceholder'

const logger = buildLogger('JotaiUrqlPageLoadingPlaceholder')

export type JotaiUrqlPageLoadingPlaceholderProps<T> = {
  children?: React.ReactNode
  documents: DocumentNode[]
  jotaiForm: JotaiForm<T>
  mode?: PageLoadingPlaceholderProps['mode']
}

function JotaiUrqlPageLoadingPlaceholder<T extends WithUrql>({
  mode,
  children,
  documents,
  jotaiForm,
}: JotaiUrqlPageLoadingPlaceholderProps<T>): JSX.Element {
  const isLoadingAtom = useMemo(() => {
    const docNames = documents.map(getDocumentName)
    return selectAtom(
      jotaiForm.atom,
      (form) => !form.urqlIsPaused || docNames.some((docName) => form.urqlIsFetching?.[docName])
    )
  }, [documents, jotaiForm.atom])
  const isLoading = useAtomValue(isLoadingAtom)
  return (
    <PageLoadingPlaceholder isLoading={isLoading} mode={mode}>
      {children}
    </PageLoadingPlaceholder>
  )
}

export default JotaiUrqlPageLoadingPlaceholder
