import { QuickCastEvokeButton } from '@/components/QuickCastBar/QuickCastEvokeButton'
import WithDynamicFeatureFlag from '@/components/WithDynamicFeatureToggle/WithDynamicFeatureToggle'
import { Grid, Typography } from '@mui/material'
import Head from 'next/head'
import Image from 'next/image'
import { useCallback, useMemo } from 'react'
import { makeStyles } from 'tss-react/mui'
import BillyLinkCard, { LinkCardProps } from '../components/card/billyLinkCard'
import JotaiUrqlErrors from '../components/error/jotaiUrqlErrors'
import InnerPageContainer from '../components/layout/innerPageContainer'
import { NavLayout } from '../components/layout/navLayout'
import JotaiUrqlPageLoadingPlaceholder from '../components/placeholder/jotaiUrqlPageLoadingPlaceholder'
import JotaiFormProvider from '../components/state/jotaiFormProvider'
import useJotaiForm from '../components/state/useJotaiForm'
import { WithUrql, useJotaiUrqlQuery } from '../components/state/useJotaiUrqlQuery'
import ApprovalRequestTable, { PendingApprovalFlowsProps } from '../components/table/approvalRequestTable'
import {
  Feature,
  GetCurrentUserDocument,
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables,
  GetPendingApprovalFlowsForUserDocument,
  GetPendingApprovalFlowsForUserQuery,
  GetPendingApprovalFlowsForUserQueryVariables,
  Role,
} from '../generated/graphql'
import { unixToUtcString } from '../util/datetime/luxon/dateUtil'
import buildLogger from '../util/logger'

const logger = buildLogger('IndexPage')

const useStyles = makeStyles()((theme, _params, _classes) => ({
  navLayout: {
    backgroundColor: theme.customPalette.landingPageBackgroundColor,
    backgroundImage: `url(${'/landing-graphic.svg'})`,
    backgroundSize: 'contain',
    backgroundPosition: 'top right',
    backgroundRepeat: 'no-repeat',
    flex: '1 1 auto',
  },
  autoHeightCards: {
    cursor: 'default',
    minHeight: 'auto',
    '&:hover': {
      boxShadow: theme.shadows[1],
    },
    '& .MuiCardHeader-root': {
      padding: theme.spacing(3),
    },
  },

  mainHeaderText: {
    fontSize: 28,
    fontWeight: 700,
    lineHeight: '40px',
    marginTop: theme.spacing(0.6),
    marginBottom: theme.spacing(0.6),
  },
  subHeaderText: {
    fontSize: 24,
    fontWeight: 500,
    lineHeight: '32px',
    marginBottom: theme.spacing(1.5),
  },
  learnMoreText: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
  },
  contactText: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
  },
  card: {
    height: '100%',
  },
}))

export interface IndexPageFormType extends WithUrql {
  currentUser?: GetCurrentUserQuery['currentUser']
  pendingApprovalFlowsForUser: PendingApprovalFlowsProps
}

function IndexPage(): JSX.Element {
  const { classes } = useStyles()

  const jotaiForm = useJotaiForm<IndexPageFormType>(
    useMemo(() => {
      return {
        defaultValue: {
          pendingApprovalFlowsForUser: [],
        },
      }
    }, [])
  )

  useJotaiUrqlQuery<IndexPageFormType, GetCurrentUserQuery, GetCurrentUserQueryVariables>({
    document: GetCurrentUserDocument,
    jotaiForm,
    onData: useCallback((data, draft) => {
      draft.currentUser = data.currentUser
    }, []),
    queryOpts: { requestPolicy: 'cache-first' },
  })

  useJotaiUrqlQuery<
    IndexPageFormType,
    GetPendingApprovalFlowsForUserQuery,
    GetPendingApprovalFlowsForUserQueryVariables
  >({
    document: GetPendingApprovalFlowsForUserDocument,
    jotaiForm,
    onData: useCallback((data, draft) => {
      draft.pendingApprovalFlowsForUser = [...data.pendingApprovalFlowsForUser]
        .sort((flow1, flow2) => flow2.updatedOn - flow1.updatedOn)
        .map((flow) => ({
          ...flow,
          updatedOn: unixToUtcString(flow.updatedOn),
        }))
    }, []),
  })

  const currentUser = jotaiForm.useSelect(useCallback((form) => form.currentUser, []))

  const options: LinkCardProps[] = useMemo(
    () => [
      {
        title: 'Accounts',
        subheader: 'Get a holistic view on your customer’s contacts, subscriptions, and billing all in one place.',
        avatar: <Image alt="icon" src="/icons/identification.svg" width={28} height={28} />,
        url: 'accounts',
      },
      {
        title: 'Orders',
        subheader:
          'Kick off the sales process by placing an order. These are the fundamental source of truth for your sale.',
        avatar: <Image alt="icon" src="/icons/inbox-in.svg" width={28} height={28} />,
        url: 'orders',
      },
      {
        title: 'Subscriptions',
        subheader:
          'Once your orders are executed, you can see and manage your subscriptions, from amending to canceling.',
        avatar: <Image alt="icon" src="/icons/subscriptions.svg" width={28} height={28} />,
        url: 'subscriptions',
      },
      {
        title: 'Invoices',
        subheader: 'Quickly access open invoices, apply and manage payments, or review posted invoices here.',
        avatar: <Image alt="icon" src="/icons/item-document.svg" width={28} height={28} />,
        url: 'invoices',
      },
      {
        title: 'Products',
        subheader:
          'Set up your products and their plans here. Create drafts to test your plans before releasing to sales.',
        avatar: <Image alt="icon" src="/icons/tag.svg" width={28} height={28} />,
        url: 'products',
      },
    ],
    []
  )

  const showApprovalRequestTable = jotaiForm.useSelect(
    useCallback((form) => !!form.pendingApprovalFlowsForUser?.length, [])
  )
  const showHereAreAFewTips = !showApprovalRequestTable

  return (
    <>
      <Head>
        <title>Home | Subskribe</title>
      </Head>
      <JotaiFormProvider form={jotaiForm}>
        <JotaiUrqlErrors jotaiForm={jotaiForm} />
        <JotaiUrqlPageLoadingPlaceholder
          mode="page"
          documents={[GetCurrentUserDocument, GetPendingApprovalFlowsForUserDocument]}
          jotaiForm={jotaiForm}
        >
          {currentUser && (
            <NavLayout className={classes.navLayout}>
              <InnerPageContainer>
                <Grid container direction="column" wrap="nowrap" spacing={3}>
                  <Grid item>
                    <Typography className={classes.mainHeaderText}>Welcome, {currentUser.displayName}!</Typography>
                    {showHereAreAFewTips && (
                      <Typography className={classes.subHeaderText}>Here are a few tips to get you started.</Typography>
                    )}
                  </Grid>
                  <Grid container item direction="row" xs={12} gap={1}>
                    <WithDynamicFeatureFlag feature={Feature.QuickAccess} status="enabled">
                      <Grid item>
                        <QuickCastEvokeButton />
                      </Grid>
                    </WithDynamicFeatureFlag>
                  </Grid>
                  {showApprovalRequestTable && (
                    <Grid item>
                      <ApprovalRequestTable />
                    </Grid>
                  )}
                  <Grid item>
                    <Grid container direction="row" wrap="wrap" spacing={3}>
                      {options.map((link: LinkCardProps) => (
                        <Grid item lg={4} md={6} xs={12} key={link.url}>
                          <BillyLinkCard
                            classNames={classes.card}
                            title={link.title}
                            subheader={link.subheader}
                            avatar={link.avatar}
                            url={link.url}
                          />
                        </Grid>
                      ))}
                      {(currentUser?.role === Role.Admin || currentUser?.role === Role.BillyAdmin) && (
                        <Grid item lg={4} md={6} sm={12}>
                          <BillyLinkCard
                            classNames={classes.card}
                            title={'Settings'}
                            subheader={
                              'Here you can manage general, users, billing, external communications, and integrations.'
                            }
                            avatar={<Image alt="icon" src="/icons/cog.svg" width={28} height={28} />}
                            url={'settings'}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </InnerPageContainer>
            </NavLayout>
          )}
        </JotaiUrqlPageLoadingPlaceholder>
      </JotaiFormProvider>
    </>
  )
}

export default IndexPage
