import React, { useCallback, useContext, useMemo } from 'react'
import { CardHeader } from '@mui/material'
import { GetPendingApprovalFlowsForUserQuery } from '../../generated/graphql'
import { IndexPageFormType } from '../../pages'
import buildLogger from '../../util/logger'
import BillyCard from '../card/billyCard'
import { JotaiFormContext } from '../state/jotaiFormProvider'
import { JotaiForm } from '../state/useJotaiForm'
import BaseTable, { BillyColumn } from './baseTable'
import { useBillyRouter } from '../route/useBillyRouter'

const logger = buildLogger('ApprovalRequestTable')

const columns: ReadonlyArray<BillyColumn> = [
  {
    Header: 'Order ID',
    accessor: 'orderId',
    size: 'shrink',
  },
  {
    Header: 'Account Name',
    accessor: 'accountName',
  },
  {
    Header: 'Approval Workflow',
    accessor: 'approvalFlowName',
  },
  {
    Header: 'TCV',
    accessor: 'tcv',
    dataType: 'currency',
    currencyField: 'currency',
  },
  {
    Header: 'Type',
    accessor: 'orderType',
    dataType: 'enum',
  },
  {
    Header: 'Last Modified',
    accessor: 'updatedOn',
    dataType: 'date',
  },
]

export type PendingApprovalFlowsProps = (Omit<
  GetPendingApprovalFlowsForUserQuery['pendingApprovalFlowsForUser'][0],
  'updatedOn'
> & {
  updatedOn: string
})[]

const ApprovalRequestTable: React.FC<React.PropsWithChildren<Record<string, unknown>>> = () => {
  const router = useBillyRouter()
  const jotaiForm = useContext<JotaiForm<IndexPageFormType>>(JotaiFormContext)

  const pendingApprovalFlowsForUser = jotaiForm.useSelect(useCallback((form) => form.pendingApprovalFlowsForUser, []))

  const handleClickPendingRow = useCallback(
    (orderId: string): void => {
      router.push(`/orders/${orderId}`)
    },
    [router]
  )

  const pendingRowHandler = useMemo(
    () => ({
      idField: 'orderId',
      onRowClick: handleClickPendingRow,
    }),
    [handleClickPendingRow]
  )

  const handleRowClick = useCallback(
    (row) => pendingRowHandler.onRowClick(row.original[pendingRowHandler.idField]),
    [pendingRowHandler]
  )
  return (
    <>
      {!!pendingApprovalFlowsForUser.length && (
        <BillyCard hidden={!pendingApprovalFlowsForUser.length}>
          <CardHeader title="Approval Requests" />
          <BaseTable
            columns={columns}
            data={pendingApprovalFlowsForUser as unknown as Record<string, unknown>[]}
            onRowClick={handleRowClick}
            rowId={pendingRowHandler?.idField || 'id'}
            pageSize={10}
          />
        </BillyCard>
      )}
    </>
  )
}

export default ApprovalRequestTable
